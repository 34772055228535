import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '@app/models/user';
import { AuthService } from '@services/user/auth.service';
/* import { navItemsAdmin, navItemsCorfid, navItemsEjecutivos, navItemsOperations, navItemsProveedor, navItemsSupervisor } from '../../containers/default-layout/_nav'; */

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  form: FormGroup;
  user!: User;

  isSubmit: boolean = false;
  existsErrorCredentials: boolean = false;

  constructor(private authService: AuthService, private router: Router, private fb: FormBuilder) {
    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required]
    })
  }

  ngOnInit(): void {
  }

  login(): void {

    if(this.form.valid) {
      this.isSubmit = false;

      this.authService.login(this.form.value).subscribe(response => {
        this.existsErrorCredentials = false;

        //Set User
        this.authService.setUser = response.data.user;
        localStorage.setItem("current_client", JSON.stringify(response.data.user));
        
        //Set Roles
        this.authService.setRoles = response.data.roles;
        localStorage.setItem("roles", JSON.stringify(response.data.roles));

        //Set Token
        localStorage.setItem("token", response.data.token);
        this.router.navigate([""]);
      }, error => {
        this.existsErrorCredentials = true;
      })

    } else {
      this.isSubmit = true;
    }    
  }
}
