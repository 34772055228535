<!--sidebar-->
<c-sidebar
  #sidebar="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar"
  visible
  style="background-color: #2f3337;"
>
  <c-sidebar-brand
  style="background: #fff;"
  >
  <img src="../../../assets/images/logo.png" alt="" width="45" >
  <!--<p class="my-0 text-center" style="color: #2a5ca7; letter-spacing: 2px; font-size: 1.3em; font-weight: bold">BILLEX</p>-->
  </c-sidebar-brand>
    <perfect-scrollbar [config]="perfectScrollbarConfig">
  <!--  [perfectScrollbar]="perfectScrollbarConfig"-->
  <c-sidebar-nav
    [navItems]="navItems"
  >
  </c-sidebar-nav>
    </perfect-scrollbar>
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar"></app-default-header>
  <!--app-body-->
  <!-- px-3 -->
  <div class="body flex-grow-1">
    <c-container fluid class="h-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer></app-default-footer>
</div>
