import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';

const routes: Routes = [
  
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'proveedor',
        loadChildren: () =>
          import('./views/vendors/vendors.module').then((m) => m.VendorsModule),
        canActivate: [RoleGuard]
      },
      {
        path: 'operaciones',
        loadChildren: () => import('./views/operations/operations.module').then((m) => m.OperationsModule),
        canActivate: [RoleGuard]
      },
      {
        path: 'ejecutivos',
        loadChildren: () => import('./views/executives/executives.module').then(m => m.ExecutivesModule),
        canActivate: [RoleGuard]
      },
      {
        path: 'administrador',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule),
        canActivate: [RoleGuard]
      },
      {
        path: 'corfid',
        loadChildren: () => import('./views/corfid/corfid.module').then(m => m.CorfidModule),
        canActivate: [RoleGuard]
      },
      {
        path: 'supervisores',
        loadChildren: () => import('./views/supervisor/supervisor.module').then(m => m.SupervisorModule),
        canActivate: [RoleGuard]
      }
    ],
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    },
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
