import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Roles, User, UserResponse, HasRoleResponse } from "@models/user";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user!: BehaviorSubject<User>;
  private roles!: BehaviorSubject<Roles>;
  private url: string = environment.API_URL;

  get getUser() {
    return this.user.asObservable();
  }

  set setUser(newUser: User) {
    this.user.next(newUser);
  }

  get getRoles() {
    return this.roles.asObservable();
  }

  set setRoles(data: Roles) {
    this.roles.next(data)
  }

  constructor(private http: HttpClient) {
    this.user = new BehaviorSubject(JSON.parse(localStorage.getItem("current_client") || '{}'))
    this.roles = new BehaviorSubject(JSON.parse(localStorage.getItem("roles") || '{}'));
  }

  login(credentials: any): Observable<UserResponse> {

    let data =  JSON.stringify(credentials);
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    })

    return this.http.post<UserResponse>(`${this.url}/api/admin/login`, data, { headers });
  }

  logout(): Observable<any> {

    //Remove Items LocalStorage
    localStorage.removeItem("roles")
    localStorage.removeItem("token")
    localStorage.removeItem("current_client")

    return this.http.post(`${this.url}/api/logout`,null);
  }

  hasRole(role: string): Observable<HasRoleResponse> {
    return this.http.get<HasRoleResponse>(`${this.url}/api/admin/has-role?role=${role}`);
  }

  hasPermission(permission: string): Observable<any> {
    return this.http.get(`${this.url}/api/admin/has-permission?permission=${permission}`);
  }

}
