<div class="modal-header">
    <h4 class="modal-title">Cambiar contraseña</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="formGroup">
        <label for="">Contraseña Anterior</label>
        <input type="password" class="form-control" formControlName="old_password">
        <label for="">Nueva Contraseña</label>
        <input type="password" class="form-control" formControlName="new_password">
    </form>
</div>
<div class="modal-footer">
    <button (click)="changePassword()" cButton color="secondary" variant="outline">
      Aceptar
    </button>
</div>