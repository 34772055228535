import Swal from "sweetalert2";

export const DATATABLES_URL = 'https://cdn.datatables.net/plug-ins/1.11.1/i18n/es_es.json';

export const showLoading = function() {
    Swal.fire(
        {
            title: '',
            allowEscapeKey: false,
            allowOutsideClick: false,
            background: 'transparent',
            showConfirmButton: false,
            didOpen: ()=>{
                Swal.showLoading();
            }
        }
    );
};

export const hideLoading = function() {
    Swal.fire(
        {
            title: '',
            allowEscapeKey: false,
            allowOutsideClick: false,
            background: 'transparent',
            showConfirmButton: false,
            timer: 1,
            didOpen: ()=>{
                Swal.hideLoading();
            }
        }
    );
};