import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { HasRoleResponse, User } from '@app/models/user';
import { AuthService } from '@app/services/user/auth.service';
import { first, Observable, tap, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanDeactivate<unknown> {

  userRoles: any = [];
  user!: User;

  constructor(private authService: AuthService, private router: Router) {}
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{

      if(!localStorage.getItem('current_client') || !localStorage.getItem('roles')){
        this.router.navigate(["login"])
        return false
      }
      return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    if(!localStorage.getItem("current_client")) {
      return true;
    }

    return this.authService.getUser.pipe(
      map(response => {
        this.user = response;
  
        if(this.user.id) {
          return false;
        }

        return true;
      })
    )

  }
  
}
