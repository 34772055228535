import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'months'
})
export class MonthsPipe implements PipeTransform {

  transform(values: any[]): string[] {
    return values.map(value => this.transformValue(value));
  }

  private transformValue(value: any): any {
    switch(value){
      case 1:
        return "Ene"
      case 2:
        return "Feb"
      case 3:
        return "Mar"
      case 4:
        return "Abr"
      case 5:
        return "May"
      case 6:
        return "Jun"
      case 7:
        return "Jul"
      case 8:
        return "Ago"
      case 9:
        return "Sep"
      case 10:
        return "Oct"
      case 11:
        return "Nov"
      case 12:
        return "Dic"
      case 0:
        return "Dic"
      default:
        return "Invalid month"
    }
  }

}
