<!--<c-footer>-->
  <div>
    <a href="https://billex.pe" target="_blank">Billex</a>
    <span> &copy; 2022</span>
  </div>
  <div class="ms-auto">
    Powered by
    <a href="https://billex.pe" target="_blank">
      <span>Billex</span>
    </a>
  </div>
<!--</c-footer>-->
