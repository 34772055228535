import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { filter } from 'rxjs';

import { navItemsAdmin, navItemsCorfid, navItemsEjecutivos, navItemsOperations, navItemsProveedor, navItemsSupervisor, navItemsAccounting } from './_nav';
import { AuthService } from '@app/services/user/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent implements OnInit {

  @Input() role: any;
  public navItems = navItemsAdmin;
  faUser = faUserCircle;

  roleUsuario: string = '';

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(private router: Router, private authService: AuthService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event: any) => {
      this.getRole(this.router.url);
    });

    this.authService.getUser.subscribe(res=>{
      this.roleUsuario = res.role.name
    });
  }

  ngOnInit(): void {
  }

  getRole(url : string) {
    var path  = url;
    var size = (path.indexOf('/', 1) > -1) ? path.indexOf('/', 1) : path.length;
    var parent = path.substring(0, size);

    if(parent == '/'){
      switch(this.roleUsuario){
        case 'admin':
          this.navItems = navItemsAdmin;
          this.router.navigate(['/administrador']);
          break;
        case 'corfid':
          this.navItems = navItemsCorfid;
          this.router.navigate(['/corfid']);
          break;
        case 'ejecutivos':
          this.navItems = navItemsEjecutivos;
          this.router.navigate(['/ejecutivos']);
          break;
        case 'operaciones':
          this.navItems = navItemsOperations;
          this.router.navigate(['/operaciones']);
          break;
        case 'proveedor':
          this.navItems = navItemsProveedor;
          this.router.navigate(['/proveedor']);
          break;
        case 'supervisor':
          this.navItems = navItemsSupervisor;
          this.router.navigate(['/supervisores']);
          break;
        case 'contabilidad':
          this.navItems = navItemsAccounting;
          this.router.navigate(['/contabilidad']);
          break;
        default:
          this.navItems = navItemsOperations;
          break;
      }
    }else{
      if( parent == '/operaciones'){
        this.navItems = navItemsOperations;
      }else if( parent == '/ejecutivos'){
        this.navItems = navItemsEjecutivos;
      }else if( parent == '/supervisores'){
        this.navItems = navItemsSupervisor;
      }else if( parent == '/administrador'){
        this.navItems = navItemsAdmin;
      } else if (parent == '/corfid') {
        this.navItems = navItemsCorfid;
      } else if(parent == '/contabilidad'){
        this.navItems = navItemsAccounting;
      } else {
        this.navItems = navItemsProveedor;
      }
    }
  }

  /*getRole(e:any): void {
    if(e === "Operaciones") {
      this.navItems = navItemsOperations;
    }else if (e === "Corfid") {
      this.navItems = navItemsCorfid
    } else if(e === "CRM") {
      this.navItems = navItemsCrm
    }else if(e === "Administrador"){
      this.navItems = navItemsAdmin
    } else {
      this.navItems = navItemsProveedor
    }
  }*/

}
