<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="5">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form cForm [formGroup]="form">
                <img src="../../../assets/images/logo.png" alt="Billex" width="60" class="d-flex mx-auto">
                <div class="mb-4 text-center">
                  <h1>Login</h1>
                  <p class="text-medium-emphasis">Sign In to your account</p>
                </div>
                <div class="mb-3">
                  <c-input-group>
                    <span cInputGroupText>
                      <svg cIcon name="cilUser"></svg>
                    </span>
                    <input 
                    cFormControl 
                    placeholder="Email"
                    formControlName="email" autocomplete="new-email"/>
                  </c-input-group>
                  <small class="text-danger text-left" *ngIf="this.form.get('email')?.errors?.['email'] && isSubmit">
                    Ingrese un correo válido.
                  </small>
                  <small class="text-danger text-left" *ngIf="this.form.get('email')?.errors?.['required'] && isSubmit">
                    Ingrese un email.
                  </small>
                </div>
                <div class="mb-4">
                  <c-input-group>
                    <span cInputGroupText>
                      <svg cIcon name="cilLockLocked"></svg>
                    </span>
                    <input
                      autoComplete="current-password"
                      cFormControl
                      placeholder="Password"
                      type="password"
                      formControlName="password"
                    />
                  </c-input-group>
                  <small class="text-danger text-left" *ngIf="this.form.get('password')?.errors?.['required'] && isSubmit">
                    Ingrese una contraseña.
                  </small>
                  <div class="text-center" *ngIf="existsErrorCredentials">
                    <small class="text-danger">
                      Datos Incorrectos.
                    </small>
                  </div>
                </div>
                <c-row>
                  <c-col xs="12" class="d-flex justify-content-center">
                    <button cButton class="px-4" color="primary" class="text-white" (click)="login()" type="submit">
                      Login
                    </button>
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>
