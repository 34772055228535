import { INavData } from '@coreui/angular';

export const navItemsAdmin: INavData[] = [

  {
    title: true,
    name: 'Menú de Administrador'
  },
  {
    name: 'Usuarios',
    url: '/theme/colors',
    iconComponent: { name: 'cil-people' }
  },
  {
    name: 'Clientes',
    url: '/base',
    iconComponent: { name: 'cil-user' },
    children: [
      {
        name: 'Registrados',
        url: '/base/accordion'
      },
      {
        name: 'Aprobados',
        url: '/base/breadcrumbs'
      },
    ]
  },
  {
    name: 'Promociones',
    url: '/buttons',
    iconComponent: { name: 'cil-star' },
    children: [
      {
        name: 'Campañas',
        url: '/buttons/buttons'
      },
      {
        name: 'Cupones',
        url: '/buttons/button-groups'
      },
    ]
  },
  {
    name: 'Tipo Cambio',
    url: '/theme/g',
    iconComponent: { name: 'cil-dollar' }
  },
  {
    name: 'Comisión',
    url: '/theme/1',
    iconComponent: { name: 'cil-money' }
  },
  {
    name: 'Gestión Bancos',
    url: '/theme/4',
    iconComponent: { name: 'cil-bank' }
  },
  {
    name: 'Tipo Cambio',
    url: '/theme/2',
    iconComponent: { name: 'cil-dollar' }
  },
  {
    name: 'Gestión Documentos',
    url: '/theme/e',
    iconComponent: { name: 'cil-file' }
  },
  {
    name: 'API Servicios',
    url: '/theme/s',
    iconComponent: { name: 'cil-code' }
  },
  {
    name: 'Configuración',
    url: '/theme/a',
    iconComponent: { name: 'cil-settings' }
  },
  {
    name: 'Mensaje de Notificación',
    url: '/theme/x',
    iconComponent: { name: 'cil-bell' }
  }
];

export const navItemsOperations: INavData[] = [
  {
    title: true,
    name: 'Menú de Operaciones'
  },
  {
    name: 'Operaciones del día',
    url: '/operaciones/diarias',
    iconComponent: { name: 'cil-chart' },
  },
  {
    name: 'Tiempos de Atención',
    iconComponent: { name: 'cil-chart' },
    url: '/base',
    children:[
      {
        name: 'Dashboard',
        url: '/operaciones/tiempo-atencion/dashboard'
      }
    ]
  }
  ,
  {
    name: 'Usuarios',
    iconComponent: { name: 'cil-user' },  
    url: '/operaciones/usuarios',
  },
  {
    name: 'Clientes',
    url: '/base',
    iconComponent: { name: 'cil-user' },
    children: [
      {
        name: 'Pendientes',
        url: '/operaciones/clientes-pendientes'
      },
      {
        name: 'Aprobados',
        url: '/operaciones/clientes-aprobados'
      },
      {
        name: 'Rechazados',
        url: '/operaciones/clientes-rechazados'
      },
      {
        name: 'Cuentas Bancarias Pendientes',
        url: '/operaciones/cuentas-pendientes'
      }
    ]
  },
  {
    name: 'Analistas',
    url: '/operaciones/analistas',
    iconComponent: { name: 'cil-user' }
  },
  {
    name: 'Tipo de cambio',
    iconComponent: { name: 'cil-dollar' },
    url: "/operaciones/tipo-cambio"
  },
  {
    name: 'Rangos operación',
    url: "/base",
    iconComponent: { name: 'cil-chart' },
    children: [
      {
        name: "Compra-Venta",
        url: "/operaciones/rangos-operacion/compra-venta"
      },
      {
        name: "Interbancaria",
        url: "/operaciones/rangos-operacion/interbancaria"
      }
    ]
  },
  {
    name:'Ejecutivos',
    iconComponent: { name: 'cil-user' },
    children:[
      {
        name: 'Comisiones',
        url:"/operaciones/ejecutivos/comisiones"
      }
    ]
  },
  {
    name: 'Reporte',
    iconComponent: { name: 'cil-chart' },
    children:[
      {
        name: 'Reporte de Ventas',
        url:"/operaciones/reportes/reporte-ventas"
      }
    ]
  },
  {
    name: 'Configuración',
    iconComponent: { name: 'cil-settings' },
    url: '/operaciones/configuracion',
  },
]
export const navItemsCorfid: INavData[] = [
  {
    title: true,
    name: 'Menú de Corfid'
  },
  {
    name: 'Clientes',
    url: '/base',
    iconComponent: { name: 'cil-user' },
    children: [
      {
        name: 'Pendientes',
        url: '/corfid/clientes-pendientes'
      },
      {
        name: 'Aprobados',
        url: '/corfid/clientes-aprobados'
      },
    ]
  },

]
export const navItemsEjecutivos: INavData[] = [
  {
    title: true,
    name: 'Menú de Ejecutivos'
  },
  {
    name: 'Dashboard',
    url: '/ejecutivos/dashboard',
    iconComponent: { name: 'cil-chart' }
  },
  {
    name: 'Prospectos',
    url: '/ejecutivos/prospectos',
    iconComponent: { name: 'cil-user' },
    children: [
      {
        name: 'Registrar',
        url: '/ejecutivos/prospectos/registrar',
        iconComponent: { name: 'cilPlaylistAdd' }
      },
      {
        name: 'Seguimiento',
        url: '/ejecutivos/prospectos/seguimiento',
        iconComponent: { name: 'cil-user' }
      },
    ]
  },
  {
    name: "Cartera",
    url: "/ejecutivos/cartera",
    iconComponent: { name: 'cil-briefcase' },
    children: [
      {
        name:"Comisiones",
        url:"/ejecutivos/cartera/comisiones"
      },
      {
        name: "Seguimiento",
        url: "/ejecutivos/cartera/seguimiento"
      },
      {
        name:"Búsqueda PJ",
        url:"/ejecutivos/cartera/search-pj"
      },
      {
        name:"Búsqueda PN",
        url:"/ejecutivos/cartera/search-pn"
      },
      {
        name: "Reporte Mensual",
        url:"/ejecutivos/cartera/reporte-mensual"
      }
    ]
  },
  {
    name: "Tipo de Cambio",
    url: "/ejecutivos/tipo-de-cambio",
    iconComponent: { name: 'cil-money' }
  }
]
export const navItemsProveedor: INavData[] = [
  {
    title: true,
    name: 'Menú de Proveedor'
  },
  {
    name: 'Dashboard',
    url: '/proveedor/dashboard',
    iconComponent: { name: 'cil-chart' }
  },
  {
    name: 'Rangos Operación',
    url: '/proveedor/rangos',
    iconComponent: { name: 'cil-chart' },
  },
  {
    name: 'Reportes Operación',
    url: '/proveedor/reportes',
    iconComponent: { name: 'cil-chart' }
  },
  {
    name: 'Cuentas Bancarias',
    url: '/proveedor/cuentas',
    iconComponent: { name: 'cil-dollar' },
  },
]

export const navItemsSupervisor: INavData[] = [
  {
    title: true,
    name: 'Menú de Supervisores'
  },
  {
    name: 'Dashboard',
    url: '/supervisores/dashboard',
    iconComponent: {name: 'cil-chart'}
  },
  {
    name:'Ejecutivos',
    url: '/supervisores/executive',
    iconComponent: {name: 'cil-chart'}
  },
  {
    name: 'Clientes',
    iconComponent: {name: 'cil-user'},
    children: [
      {
        name: 'Reporte Mensual',
        url: '/supervisores/clientes/reporte-mensual',
      }
    ]
  }
]

export const navItemsAccounting: INavData[] = [
  {
    title: true,
    name: 'Menú de Contabilidad'
  },
  {
    name: 'Dashboard',
    iconComponent: {name: 'cil-chart'},
    children: [
      {
        name: 'Información general de compras y ventas',
        url: '/contabilidad/dashboard'
      }
    ]
  },
  {
    name: 'Compras',
    iconComponent: {name: 'cil-chart'},
    children: [
      {
        name: 'Registro nueva compra',
        url: '/contabilidad/compras/registrar-compra'
      },
      {
        name: 'Lista de compras',
        url: '/contabilidad/compras/listar-compras'
      },
      {
        name: 'Reporte pagos pendientes',
        url: '/contabilidad/compras/pendientes-pago'
      },
      {
        name: 'Detracciones',
        url: '/contabilidad/compras/pago-detracciones'
      }
    ]
  },
  {
    name: 'Ventas',
    iconComponent: {name: 'cil-chart'},
    children: [
      {
        name: 'Nueva venta',
        url: '/contabilidad/ventas/registrar-venta'
      },
      {
        name: 'Lista de ventas',
        url: '/contabilidad/ventas/lista-ventas'
      },
      {
        name: 'Autodetracciones',
        url: '/contabilidad/ventas/autodetracciones'
      }
    ]
  },
  {
    name: 'Planilla',
    iconComponent: {name: 'cil-chart'},
    children: [
      {
        name: 'Registro nuevo personal',
        url: '/contabilidad/planilla/registro-personal'
      },
      {
        name: 'Listado de personas planilla',
        url: '/contabilidad/planilla/listado-personal'
      },
      {
        name: 'Vacaciones',
        url: '/contabilidad/planilla/vacaciones'
      }
    ]
  },
  {
    name: 'Proveedor',
    iconComponent: {name: 'cil-chart'},
    children: [
      {
        name: 'Registro nuevo proveedor',
        url: '/contabilidad/proveedor/crear-proveedor'
      },
      {
        name: 'Listado de proveedores',
        url: '/contabilidad/proveedor/listar-proveedores'
      }
    ]
  },
  {
    name: 'Presupuesto',
    iconComponent: {name: 'cil-chart'},
    children: [
      {
        name: 'Generación de partida presupuestal',
        url: '/contabilidad/presupuesto/crear-presupuesto'
      }
    ]
  },
  {
    name: 'Servicios',
    iconComponent: {name: 'cil-chart'},
    children: [
      {
        name: 'Creación de nuevo servicio',
        url: '/contabilidad/servicio/crear-servicio'
      },
      {
        name: 'Listado de servicios',
        url: '/contabilidad/servicio/listar-servicios'
      }
    ]
  },
  {
    name: 'Otros gastos e ingresos',
    iconComponent: {name: 'cil-chart'},
    children: [
      {
        name: 'Registro de otros ingresos',
        url: '/contabilidad/otros-gastos/registro-ingresos'
      },
      {
        name: 'Registro de otros gastos',
        url: '/contabilidad/otros-gastos/registro-gastos'
      }
    ]
  },
  {
    name: 'Bancos',
    iconComponent: {name: 'cil-chart'},
    children: [
      {
        name: 'Creación de cuenta bancaria',
        url: '/contabilidad/bancos/registro-cuenta-bancaria'
      },
      {
        name: 'Detalle de movimiento',
        url: '/contabilidad/bancos/detalle-movimiento'
      },
      {
        name: 'Subir estado de cuenta y movimiento',
        url: '/contabilidad/bancos/estado-cuenta-movimiento'
      }
    ]
  },
  {
    name: 'Reportes',
    iconComponent: {name: 'cil-chart'},
    children: [
      {
        name: 'Flujo Caja',
        url: '/contabilidad/reportes/flujo-caja'
      },
      {
        name: 'Estado financiero',
        url: '/contabilidad/reportes/estado-financiero'
      }
    ]
  }
]